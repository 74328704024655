import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    Editor,
} from 'components'
import {
    User,
    News,
    Authority
} from 'app/models'
import {
    PhotoUploader,
} from 'components/uploader'

class EditComponent {
    constructor(vnode) {
        this.content = stream("")
        this.preview = stream("")
        this.model = vnode.attrs.model
        if (this.model.id != 0) {
            this.content(JSON.parse(this.model.content))
            this.preview(this.model.banner)
        }
    }
    getPhotoPath(filename) {
        if (filename == undefined || filename == null || filename == '') {
            return `${window.BASE_URL}/images/news/1140_570.jpg`
        }
        return `${window.BASE_URL}/images/news/${filename}`
    }

    save() {
        this.model.content = JSON.stringify(this.content())
        this.model.banner = this.preview()
        this.model.save().then((response) => {
            Dialog.close(true)
        })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return [m(".modal-dialog.modal-lg[role='document']", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", (this.model.id == 0) ? "紀錄" : "編輯"),
                            m("button.close", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m("",
                            m("form[id='basic-form'][method='post'][novalidate]",
                                m('.row.m-0', [
                                    m(".col-6", [
                                        m(PhotoUploader, {
                                            caption: '視覺圖 1170*750',
                                            path: this.getPhotoPath(this.model.banner),
                                            url: `${window.BASE_URL}/api/upload/photo`,
                                            preview: true,
                                            width: 1170,
                                            height: 750,
                                            viewW: 320,
                                            viewH: 180,
                                            filename: this.preview,
                                            folder: "news",
                                        })
                                    ]),
                                    m('.col-md-6',
                                        m(".row.no-gutters", [
                                            m(".col-12",
                                                m(".form-group", [
                                                    m("label.text-dark.mb-1", "標題"),
                                                    [
                                                        m(TextBox, {
                                                            oninput: (e) => {
                                                                this.model.title = e.target.value
                                                            },
                                                            class: 'mt-0 form-control',
                                                            value: this.model.title,
                                                            type: 'text',
                                                        })
                                                    ]
                                                ]),
                                            ),
                                            m('.col-md-12',
                                                m(".form-group", [
                                                    m("label.text-dark.mb-1", "排序"),
                                                    [
                                                        m(TextBox, {
                                                            oninput: (e) => {
                                                                this.model.sort = e.target.value
                                                            },
                                                            class: 'mt-0 form-control',
                                                            value: this.model.sort,
                                                            type: 'text',
                                                        })
                                                    ]
                                                ]),
                                            ),
                                            m(".col-6", [
                                                m(".form-radio.form-radio-flat.my-2",
                                                    m("label.form-check-label.pl-4",
                                                        [
                                                            m("input.form-check-input[type='radio']", {
                                                                checked: this.model.isHidden == "0",
                                                                onclick: () => {
                                                                    this.model.isHidden = "0"
                                                                }
                                                            }),
                                                            "顯示",
                                                            m("i.input-helper")
                                                        ]
                                                    )
                                                ),
                                            ]),
                                            m(".col-6", [
                                                m(".form-radio.form-radio-flat.my-2",
                                                    m("label.form-check-label.pl-4",
                                                        [
                                                            m("input.form-check-input[type='radio']", {
                                                                checked: this.model.isHidden == "1",
                                                                onclick: () => {
                                                                    this.model.isHidden = "1"
                                                                }
                                                            }),
                                                            "不顯示",
                                                            m("i.input-helper")
                                                        ]
                                                    )
                                                )
                                            ]),


                                        ])

                                    ),


                                ]),
                                m('.row.m-0', [
                                    m(".form-group",
                                        [
                                            m("label.control-label", "內容"),
                                            m(Editor, {
                                                content: this.content
                                            }),
                                        ]
                                    )
                                ]),
                            )
                        )
                    ),

                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                [m("i.fa.fa-times-circle"), "關閉"]
                            ),
                            m("button.btn.btn-primary[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.save()
                                    swal({
                                        title: (this.model.id == 0) ? "新增成功" : "編輯成功!",
                                        text: (this.model.id == 0) ? "已成功新增資訊，請至列表查看!" : "已成功編輯資訊，請至列表查看!!",
                                        icon: "success",
                                        buttons: false,
                                        timer: 1800,
                                    })
                                }
                            },

                                [m("i.fa.fa-check-circle"), "儲存"])
                        ],
                    )
                ]
            )
        ])]
    }
}

export default EditComponent
