export const ManagementItems = [{
    href: '/',
    title: 'Dashboard',
    icon: 'lnr.lnr-home',
    regex: /\//,
    auth: 'dashboard',
},
// {
//     href: '/about',
//     title: 'About',
//     icon: 'lnr.lnr-user',
//     regex: /\/about/,
//     auth: 'About'
// }, 
{
    href: '/animation',
    title: 'Animation',
    icon: 'lnr.lnr-star',
    regex: /\/animation/,
    auth: 'animation'
},
{
    href: '/web',
    title: 'Web',
    icon: 'lnr.lnr-star',
    regex: /\/web/,
    auth: 'Web'
}, {
    href: '/brand',
    title: 'Brand',
    icon: 'lnr.lnr-star',
    regex: /\/brand/,
    auth: 'Brand'
}, {
    href: '/graphics',
    title: 'Graphics',
    icon: 'lnr.lnr-star',
    regex: /\/graphics/,
    auth: 'Graphics'
}, {
    href: '/illustration',
    title: 'Illustration',
    icon: 'lnr.lnr-star',
    regex: /\/illustration/,
    auth: 'Illustration'
}, {
    href: '/other',
    title: 'Other',
    icon: 'lnr.lnr-star',
    regex: /\/other/,
    auth: 'other'
}, {
    href: '/news',
    title: 'News',
    icon: 'lnr.lnr-calendar-full',
    regex: /\/news/,
    auth: 'News'
}, {
    href: '/customers',
    title: 'Customers',
    icon: 'lnr.lnr-users',
    regex: /\/customers/,
    auth: 'Customers'
},
{
    href: '/contactUs',
    title: 'ContactUs',
    icon: 'lnr.lnr-phone-handset',
    regex: /\/contactUs/,
    auth: 'contactUs'
},
    // {
    //     href: '/contact',
    //     title: 'Contact',
    //     icon: 'lnr.lnr-phone-handset',
    //     regex: /\/contact/,
    //     auth: 'Contact'
    // },

]



