import m from 'mithril'
import EditComponent from './edit'
import moment from 'moment'
import {
    Dialog
} from 'components'
import {
    Animation,
} from 'app/models'

class AnimationPage {
    constructor() {
        Animation.fetch()
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder",
                        [
                            m("h1.main-title.float-left.text-menu",
                                m("i.lnr.lnr-book.font-weight-bold.mr-3"), "Animation"
                            ),
                            m("ol.breadcrumb.float-right",
                                [
                                    m("li.breadcrumb-item",
                                        "Dashboard"
                                    ),
                                    m("li.breadcrumb-item.active",
                                        "Animation List"
                                    )
                                ]
                            ),
                            m(".clearfix")
                        ]
                    )
                )
            ),
            m(".row",
                m(".col-md-12",
                    m(".panel-content",
                        m(".card.mb-3",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-table.mr-2"), "Animation List"]),
                                            m("button.btn.btn-primary.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Dialog.show(EditComponent, {
                                                        attrs: {
                                                            model: new Animation(),
                                                        },
                                                        didHide: (returnValue) => {
                                                            if (returnValue) {
                                                                Animation.fetch()
                                                            }
                                                        }
                                                    })
                                                }
                                            }, [m("i.fa.fa-plus-circle.mr-1"), "New Animation"])
                                        ]
                                    ),
                                ),
                                m(".card-header", [
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "序號")
                                        ),
                                        m(".col-4",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "標題")
                                        ),
                                        m(".col-3.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "描述")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "關鍵字")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "排序")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "功能")
                                        ),

                                    ]),

                                    (Animation.loading) ?
                                        [
                                            m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                style: {
                                                    fontSize: "85%",
                                                    fontWeight: 700,
                                                    lineHeight: 1,
                                                }
                                            }, [
                                                    m('.col-12.text-center.py-2', [
                                                        m('span', {
                                                            style: {
                                                                fontSize: "1.5rem"
                                                            }
                                                        }, "資料讀取中...")
                                                    ])
                                                ])

                                        ] : [
                                            (Animation.data.length == 0) ?
                                                [
                                                    m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                        style: {
                                                            fontSize: "85%",
                                                            fontWeight: 700,
                                                            lineHeight: 1,
                                                        }
                                                    }, [
                                                            m('.col-12.text-center.py-2', [
                                                                m('span', {
                                                                    style: {
                                                                        fontSize: "1.5rem"
                                                                    }
                                                                }, "無資料")
                                                            ])
                                                        ])

                                                ] : [

                                                    Animation.data.map((_Animation, index) => {
                                                        return m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                            style: {
                                                                fontSize: "85%",
                                                                fontWeight: 700,
                                                                lineHeight: 1,
                                                                backgroundColor: (index % 2 == 0) ? "#f2f2f2" : "#FFF"
                                                            }
                                                        }, [
                                                                m(".col-1.text-center",
                                                                    m("span", index + 1 )
                                                                ),
                                                                m(".col-4",
                                                                    m("span", _Animation.title)
                                                                ),
                                                                m(".col-3.text-center",
                                                                    m("span", _Animation.description)
                                                                ),
                                                                m(".col-2.text-center",
                                                                    m("span", _Animation.keywords)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("span", _Animation.sort)
                                                                ),
                                                                m(".col-1.text-center",
                                                                    m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                        style: {
                                                                            backgroundColor: "#e2f0d9"
                                                                        },
                                                                        onclick: (e) => {
                                                                            e.preventDefault()
                                                                            Dialog.show(EditComponent, {
                                                                                attrs: {
                                                                                    model: new Animation(_Animation),
                                                                                },
                                                                                didHide: (returnValue) => {
                                                                                    if (returnValue) {
                                                                                        Animation.fetch()
                                                                                    }
                                                                                }
                                                                            })
                                                                        }
                                                                    },
                                                                        [
                                                                            m("i.fa.fa-edit"),
                                                                        ]
                                                                    ),
                                                                    m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                        style: {
                                                                            backgroundColor: "#ffbdbe"
                                                                        },
                                                                        onclick: (e) => {
                                                                            e.preventDefault()
                                                                            let _temp = new Animation(_Animation)
                                                                            swal({
                                                                                title: "是否確認刪除",
                                                                                text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                                icon: "warning",
                                                                                dangerMode: true,
                                                                                buttons: ["取消", "確認"]
                                                                            })
                                                                                .then((willDelete) => {
                                                                                    if (willDelete) {
                                                                                        swal("您的資料已成功刪除", {
                                                                                            icon: "success",
                                                                                            buttons: {
                                                                                                cancel: false,
                                                                                                confirm: "確認",
                                                                                            },
                                                                                        });
                                                                                        _temp.delete().then(() => {
                                                                                            Animation.fetch()
                                                                                        })
                                                                                    } else {
                                                                                        swal("您的資料未被刪除");
                                                                                    }
                                                                                });
                                                                        }
                                                                    },
                                                                        [
                                                                            m("i.fa.fa-trash"),
                                                                        ]
                                                                    )
                                                                ),
                                                            ])

                                                    }),
                                                ]
                                        ]
                                ]
                                ),
                               
                            ]
                        )
                    )
                )
            ),
        ]
    }
}

export default AnimationPage