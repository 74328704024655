import m from "mithril";


class Editor {

    constructor(vnode) {
        this.content = vnode.attrs.content
        this.editor = null

    }
    oncreate(vnode) {
        this.editor = CKEDITOR.replace(vnode.dom)

        this.editor.on("instanceReady", (e) => {
            this.editor.setData(this.content())
            this.editor.on('change', (e) => {
                this.content(e.editor.getData())
            })
            this.editor.on('afterInsertHtml', (e) => {
                this.content(e.editor.getData())
            })
        })

        CKEDITOR.replace('editor', {
            plugins: 'wysiwygarea,toolbar,sourcearea,image,basicstyles',
            on: {
                instanceReady: function () {
                    this.dataProcessor.htmlFilter.addRules({
                        elements: {
                            img: function (el) {
                                el.addClass('img-responsive');
                            }
                        }
                    });
                }
            }
        });
    }

    onremove(vnode) {
        if (this.editor) {
            CKEDITOR.remove(this.editor)
        }
    }

    view({
        attrs
    }) {
        return m('.w-100', [
            m.trust(this.content())
        ])
    }
}

export default Editor