export {
    default as PhotoUploader
}
from './photo'

export {
    default as DataUploader
}
from './data'


export {
    default as MultiPhotosUploader
}
from './photos'

export {
    default as BannersPhotosUploader
}
    from './banners'

export {
    default as Uploader
}
    from './uploader'