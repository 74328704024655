import m from 'mithril'
import EditComponent from './edit'
import classNames from 'classnames'
import isMobile from 'lib/detect-mobile'
import moment from 'moment'
import {
    Dialog
} from 'components'
import {
    News,
    Authority
} from 'app/models'
import {
    Paging,
} from 'components'


class NewsPage {
    constructor() {
        // this.news = []
        News.fetch().then((response) => {
            // this.news = response
        })
        this.today = moment().format("YYYY-MM-DD")
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder",
                        [
                            m("h1.main-title.float-left.text-menu",
                                m("i.lnr.lnr-book.font-weight-bold.mr-3"), "News"
                            ),
                            m("ol.breadcrumb.float-right",
                                [
                                    m("li.breadcrumb-item",
                                        "Dashboard"
                                    ),
                                    m("li.breadcrumb-item.active",
                                        "News"
                                    )
                                ]
                            ),
                            m(".clearfix")
                        ]
                    )
                )
            ),
            m(".row",
                m(".col-md-12",
                    m(".panel-content",
                        m(".card.mb-3",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-table.mr-2"), "News"]),
                                            m("button.btn.btn-primary.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    Dialog.show(EditComponent, {
                                                        attrs: {
                                                            model: new News(),
                                                        },
                                                        didHide: (returnValue) => {
                                                            if (returnValue) {
                                                                News.fetch().then((response) => {
                                                                    this.news = response
                                                                })
                                                            }
                                                        }
                                                    })
                                                }
                                            }, [m("i.fa.fa-plus-circle.mr-1"), "New News"])
                                        ]
                                    ),
                                ),
                                m(".card-header", [
                                    m(".row.w-100.text-light.border-bottom.pb-1", [
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "序號")
                                        ),
                                        m(".col-7",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "標題")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "顯示")
                                        ),
                                        m(".col-2.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "排序")
                                        ),
                                        m(".col-1.text-center",
                                            m("span.font-weight-bold", {
                                                style: {
                                                    color: "#4c73b4"
                                                }
                                            }, "功能")
                                        ),

                                    ]),

                                    (News.loading) ?
                                        [
                                            m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                style: {
                                                    fontSize: "85%",
                                                    fontWeight: 700,
                                                    lineHeight: 1,
                                                }
                                            }, [
                                                m('.col-12.text-center.py-2', [
                                                    m('span', {
                                                        style: {
                                                            fontSize: "1.5rem"
                                                        }
                                                    }, "資料讀取中...")
                                                ])
                                            ])

                                        ] : [
                                            (News.data.length == 0) ?
                                                [
                                                    m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                        style: {
                                                            fontSize: "85%",
                                                            fontWeight: 700,
                                                            lineHeight: 1,
                                                        }
                                                    }, [
                                                        m('.col-12.text-center.py-2', [
                                                            m('span', {
                                                                style: {
                                                                    fontSize: "1.5rem"
                                                                }
                                                            }, "無資料")
                                                        ])
                                                    ])

                                                ] : [

                                                    News.data.map((_news, index) => {
                                                        return m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                            style: {
                                                                fontSize: "85%",
                                                                fontWeight: 700,
                                                                lineHeight: 1,
                                                                backgroundColor: (index % 2 == 0) ? "#f2f2f2" : "#FFF"
                                                            }
                                                        }, [
                                                            m(".col-1.text-center",
                                                                m("span", index + 1 + (News.paging.pageNo * News.paging.pageSize) - 15)
                                                            ),
                                                            m(".col-7",
                                                                m("span", _news.title)
                                                            ),
                                                            m(".col-1.text-center",
                                                                (_news.isHidden == "0") ? [m("i.fa.fa-check.text-success")] : [m("i.fa.fa-remove.text-danger")]
                                                            ),
                                                            m(".col-2.text-center",
                                                                m("span", _news.sort)
                                                            ),
                                                            m(".col-1.text-center",
                                                                m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                    style: {
                                                                        backgroundColor: "#e2f0d9"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        Dialog.show(EditComponent, {
                                                                            attrs: {
                                                                                model: new News(_news),
                                                                            },
                                                                            didHide: (returnValue) => {
                                                                                if (returnValue) {
                                                                                    News.fetch()
                                                                                }
                                                                            }
                                                                        })
                                                                    }
                                                                },
                                                                    [
                                                                        m("i.fa.fa-edit"),
                                                                    ]
                                                                ),
                                                                m("button.btn.btn-sm.mx-0.mx-md-1.my-1.my-md-0.px-2", {
                                                                    style: {
                                                                        backgroundColor: "#ffbdbe"
                                                                    },
                                                                    onclick: (e) => {
                                                                        e.preventDefault()
                                                                        swal({
                                                                            title: "是否確認刪除",
                                                                            text: "刪除後將無法復原，請確認是否要刪除此筆資料!",
                                                                            icon: "warning",
                                                                            dangerMode: true,
                                                                            buttons: ["取消", "確認"]
                                                                        })
                                                                            .then((willDelete) => {
                                                                                if (willDelete) {
                                                                                    swal("您的資料已成功刪除", {
                                                                                        icon: "success",
                                                                                        buttons: {
                                                                                            cancel: false,
                                                                                            confirm: "確認",
                                                                                        },
                                                                                    });
                                                                                    News.delete(_news.id).then(() => {
                                                                                        News.fetch()
                                                                                    })
                                                                                } else {
                                                                                    swal("您的資料未被刪除");
                                                                                }
                                                                            });
                                                                    }
                                                                },
                                                                    [
                                                                        m("i.fa.fa-trash"),
                                                                    ]
                                                                )
                                                            ),
                                                        ])

                                                    }),
                                                ]
                                        ]
                                ]
                                ),
                                m('.row.no-gutters.px-5', [
                                    m('.col-12.pt-3', [
                                        m('.d-flex.flex-sm-row.flex-column.justify-content-md-between', [
                                            m(Paging.Pagination, {
                                                pageNo: News.paging.pageNo,
                                                pageCount: News.paging.pageCount,
                                                pageUrl: News.fetch.bind(this)
                                            }),
                                            m(Paging.PageItemCount, {
                                                pageNo: News.paging.pageNo,
                                                pageSize: News.paging.pageSize,
                                                total: News.paging.totalRecordCount
                                            })
                                        ])
                                    ])
                                ]),
                            ]
                        )
                    )
                )
            ),
        ]
    }
}

export default NewsPage