export * from './menu'
export { default as About } from './about'
export { default as Web } from './web'
export { default as Animation } from './animation'
export { default as Other } from './other'
export { default as Brand } from './brand'
export { default as Graphics } from './graphics'
export { default as Illustration } from './illustration'
export { default as Customers } from './customers'
export { default as User } from './user'
export { default as Authority } from './authority'
export { default as News } from './news'
export { default as ContactUs } from './contactUs'

