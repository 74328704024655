import m from 'mithril'
import stream from 'mithril/stream'
import * as Procedure from "app/models/procedure-status"
import {
    Dialog,
    TextBox,
    Button
} from 'components'
import {
    Authority,
} from "app/models"
import moment from 'moment'


class EventDialog {
    constructor(vnode) {
        this.model = vnode.attrs.model
        if(this.model.type == ""){
            this.model.type = "contact"
        }
        this.status = [{
            name: "聯絡我們",
            value: "contact"
        }, {
            name: "線上報名",
            value: "signup"
        }, {
            name: "客製合作",
            value: "customer"
        },]
    }
    close(event, value) {
        setTimeout(() => {
            Dialog.close(false)
            m.redraw()
        }, 200)
    }

    save() {
        this.model.save().then((response) => {
            Dialog.close(true)
        })
    }

    view({
        attrs
    }) {
        return m('.modal-dialog', [
            m('.modal-content', [
                m('.modal-header', [
                    m('h5.modal-title', [
                        m('strong', '信箱')
                    ]),
                    m('button[type="button"].close', {
                        onclick: this.close.bind(this)
                    }, [
                            m('span', m.trust('&times;'))
                        ])
                ]),
                m(".modal-body", [
                    m('.container-fluid', [
                        m('.row', [
                            m('.col-12',
                                m(".form-group", [
                                    m("label.text-dark.mb-1", "信箱"),
                                    [
                                        m(TextBox, {
                                            oninput: (e) => {
                                                this.model.email = e.target.value
                                            },
                                            class: 'mt-0 form-control',
                                            value: this.model.email,
                                            type: 'text',
                                        })
                                    ]
                                ]),
                            ),
                            m('.col-12',
                                m(".form-group", [
                                    m("label.text-dark.mb-1", "種類"),
                                    [
                                        m("select.form-control", {
                                            onchange: (e) => {
                                                this.model.type = e.target.value
                                            }
                                        }, [
                                                this.status.map((item, index) => {
                                                    return m('option', {
                                                        value: item.value,
                                                        selected: this.model.status == item.value
                                                    }, item.name)
                                                })
                                            ]
                                        )
                                    ]
                                ]),
                            ),
                            m('.w-100'),
                        ])
                    ])
                ]),
                m('.modal-footer', [
                    m(Button, {
                        class: 'btn-success',
                        onclick: (e) => {
                            e.preventDefault()
                            this.save()
                        }
                    }, '儲存'),
                    m(Button, {
                        class: 'btn-outline-secondary',
                        onclick: this.close.bind(this)
                    }, '關閉')
                ])
            ])
        ])
    }
}

export default EventDialog