export { default as DashboardPage } from './management/dashboard'
export { default as AboutPage } from './management/about'
export { default as WebPage } from './management/web'
export { default as BrandPage } from './management/brand'
export { default as GraphicsPage } from './management/graphics'
export { default as IllustrationPage } from './management/illustration'
export { default as CustomersPage } from './management/customers'
export { default as NewsPage } from './management/news'

export { default as OtherPage } from './management/other'
export { default as AnimationPage } from './management/animation'
export { default as ContactUsPage } from './management/contactUs'


export { default as AccountPage } from './management/account'
export { default as ErrorPage } from './error'

