import m from 'mithril'
import AjaxLoader from 'components/preloader/ajax_loader'
import isMobile from 'lib/detect-mobile'
import Chunk from 'lib/chunk'
import Layout from 'app/container/layout'
import * as Page from 'app/pages'
import {
    Authority,
    User,
} from 'app/models'

const root = document.body

window.isMobile = isMobile()

const managerRoutes = {
    '/': Layout(Page.DashboardPage),
    '/about': Layout(Page.AboutPage),
    '/web': Layout(Page.WebPage),
    '/brand': Layout(Page.BrandPage),
    '/other': Layout(Page.OtherPage),
    '/animation': Layout(Page.AnimationPage),
    '/graphics': Layout(Page.GraphicsPage),
    '/illustration': Layout(Page.IllustrationPage),
    '/customers': Layout(Page.CustomersPage),
    '/news': Layout(Page.NewsPage),
    '/account': Layout(Page.AccountPage),
    '/contactUs': Layout(Page.ContactUsPage),
    "/:404...": Page.ErrorPage,
}

export function render() {
    // const loader = document.getElementById('loader');

    if (window.isMobile) {
        $("body").addClass("mobile").removeClass("adminbody");
    }

    Promise.all([User.load(window.USER_ID)]).then(() => {
        // console.log(JSON.parse(User.Account.permission))
        m.route(root, '/', managerRoutes)
    })
}
