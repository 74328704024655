import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: "^請輸入名字"
        }
    },

    password: function (value, attributes, attributeName, options, constraints) {
        if (attributes.changePassword) {
            return {
                presence: {
                    allowEmpty: false,
                    message: "^請輸入新密碼"
                },
                format: {
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/,
                    message: "^請輸入至少6個字元，需包含英文大小寫及數字"
                }
            }
        }

        return false;
    },
    confirmPassword: function (value, attributes, attributeName, options, constraints) {
        if (attributes.changePassword) {
            return {
                presence: {
                    allowEmpty: false,
                    message: "^請輸入確認密碼"
                },
                equality: {
                    attribute: "password",
                    message: "^密碼不相符",
                    comparator: (v1, v2) => {
                        return JSON.stringify(v1) === JSON.stringify(v2);
                    }
                }
            }
        }
        return false;
    }
}


let _user = {}

class User extends BaseModel {

    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || '';
        this.name = args.name || null;
        this.email = args.email || '';
        this.permission = args.permission || '';
        this.changePassword = false
        this.password = ''
        this.confirmPassword = ''
    }

    static get Account() {
        return _user
    }

    static fetch() {
        User.loading = true
        return m.request({
            method: 'GET',
            url: `/api/users`,
        }).then((response) => {
            _user = response
            User.loading = false
            return response
        })
    }

    static remove(id) {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/users/${id}`,
        })
    }

    static sendAuthorizeMail(id) {
        return m.request({
            method: 'POST',
            url: `/api/users/resendmail`,
            data: id
        })
    }

    static disabled(id) {
        return m.request({
            method: 'PUT',
            url: `${window.BASE_URL}/api/users/disabled/${id}`,
        })
    }

    static enabled(id) {
        return m.request({
            method: 'PUT',
            url: `${window.BASE_URL}/api/users/enabled/${id}`,
        })
    }

    static load(id) {
        User.loading = true
        return m.request({
            method: 'GET',
            url: `/api/users/${id}`
        }).then((response) => {
            _user = response
            User.loading = false
            return response
        })
    }

    save() {
        if (!this.id) {
            return this.create()
        }
        return this.update()
    }

    static create(data, data_id) {
        return m.request({
            method: 'POST',
            url: `/api/users`,
            data: [data, data_id]
        })
    }

    update() {
        return m.request({
            method: 'PUT',
            url: `${window.BASE_URL}/api/users/${this.id}`,
            data: this
        })
    }
    

    updatePassword() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/users/change-password`,
            data: this
        })
    }
    
}

export default User