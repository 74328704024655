import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []

const constraints = {

}

class About extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.id = args.id || 0;
        this.content = args.content || '';
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }

    static get data() {
        return _data;
    }

    static fetch() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/about/1`,
        }).then((response) => {
            About.loading = false
            _data = response
            return response
        })
    }

    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/about`,
            data: this
        })
    }
}

export default About