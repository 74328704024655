import m from 'mithril'
import moment from 'moment'
import BaseModel from './_base'

let _data = []

const constraints = {

}

class Other extends BaseModel {
    constructor(args) {
        super(constraints)
        args = (args) ? args : {};
        this.category = 'other'
        this.id = args.id || 0;
        this.title = args.title || '';
        this.description = args.description || '';
        this.keywords = args.keywords || '';
        this.preview = args.preview || '';
        this.content = args.content || '';
        this.youtube = args.youtube || '';
        this.website = args.website || '';
        this.sort = args.sort || 0;
        this.updated_at = moment(args.updated_at).format('Y-M-D') || moment().format('Y-M-D');
        this.created_at = moment(args.created_at).format('Y-M-D') || moment().format('Y-M-D');
    }

    static get data() {
        return _data;
    }

    static fetch() {
        return m.request({
            method: 'GET',
            url: `${window.BASE_URL}/api/article/other`,
        }).then((response) => {
            Other.loading = false
            _data = response
            return response
        })
    }
    save() {
        return m.request({
            method: 'POST',
            url: `${window.BASE_URL}/api/article`,
            data: this
        })
    }

    delete() {
        return m.request({
            method: 'DELETE',
            url: `${window.BASE_URL}/api/article/`,
            data: this
        })
    }

}

export default Other