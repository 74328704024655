import m from 'mithril'
import {
    Mail,
    Banner
} from "app/models"
import stream from 'mithril/stream'
import * as Procedure from "app/models/procedure-status"
import {
    Dialog
} from 'components'
import moment from 'moment'
import EditComponent from './dialogs/event'
import {
    BannersPhotosUploader,
} from 'components/uploader'

class DashboardPage {
    constructor() {        
        
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder", [
                        m("h1.main-title.float-left.text-menu", m("i.lnr.lnr-home.font-weight-bold.mr-3"), "首頁"),
                        m("ol.breadcrumb.float-right",
                            [
                                m("li.breadcrumb-item.active", "首頁")
                            ]
                        ), m(".clearfix")
                    ])
                )
            ),
            m(".row",
                // m(".col-md-12",[
                //     m(".panel-content",
                //         m(".card.mb-3",
                //             [
                //                 m(".card-header",
                //                     m('.d-flex.justify-content-between.align-items-center',
                //                         [
                //                             m("h3", [m("i.fa.fa-table.mr-2"), "帥哥一枚"]),
                //                             // m("button.btn.btn-primary.btn-sm[type='button']", {
                //                             //     onclick: (e) => {
                //                             //         e.preventDefault()
                //                             //         Banner.save(this.banners()).then((response)=>{
                //                             //             swal({
                //                             //                 title: "儲存完畢!",
                //                             //                 text: "已成功編輯資訊，請至列表查看!!",
                //                             //                 icon: "success",
                //                             //                 buttons: false,
                //                             //                 timer: 1800,
                //                             //             })
                //                             //         })
                //                             //     }
                //                             // }, [
                //                             //     m("i.fa.fa-plus-circle.mr-1"), "儲存首頁視覺圖"
                //                             // ])
                //                         ]
                //                     ),
                //                 ),
                //                 // m(".card-header", [
                //                 //     m(".row.w-100.text-light.border-bottom.pb-1.d-flex.justify-content-end", [
                //                 //         m(".col-4",[
                //                 //             m("img.img-fluid", {
                //                 //                 src: './images/img_1.jpg',
                //                 //             })
                //                 //         ])                                        
                //                 //     ])
                //                 // ])
                //             ]
                //         )
                //     )]
                // ),                
            )
        ]
    }
}

export default DashboardPage