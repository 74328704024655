import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Dialog,
    TextBox,
    Editor,
} from 'components'

import DatePicker from 'components/datepick/mithril-datepicker.js'
import {
    MultiPhotosUploader,
} from 'components/uploader'
import {
    PhotoUploader,
} from 'components/uploader'


class EditComponent {
    constructor(vnode) {
        this.preview = stream("")
        this.content = stream("")
        this.model = vnode.attrs.model
        if (this.model.id != 0) {
            this.preview(this.model.preview)
            this.content(this.model.content)
        }
    }
    
    getPhotoPath(filename) {
        console.log(filename, filename == undefined || filename == null || filename == '')
        if (filename == undefined || filename == null || filename == '') {
            return `${window.BASE_URL}/images/960x960.png`
        }
        return `${window.BASE_URL}/images/animation/${filename}`
    }

    save() {
        this.model.content = this.content()
        this.model.preview = this.preview()
        this.model.save().then((response) => {
            swal({
                title: (this.model.id == 0) ? "新增成功" : "編輯成功!",
                text: (this.model.id == 0) ? "已成功新增資訊，請至列表查看!" : "已成功編輯資訊，請至列表查看!!",
                icon: "success",
                buttons: false,
                timer: 1800,
            }).then((_response)=>{
                Dialog.close(true)
            })
        })
    }
    close(event) {
        this._close()
    }
    _close(value) {
        setTimeout(() => {
            Dialog.close(value)
            m.redraw()
        }, 200)
    }
    view(vnode) {
        return [m(".modal-dialog.modal-lg[role='document']", [
            m(".modal-content",
                [
                    m(".modal-header",
                        [
                            m("h5.modal-title", (this.model.id == 0) ? "新增" : "編輯"),
                            m("button.close", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                m("span", m.trust("&times;"))
                            ),
                        ]
                    ),
                    m(".modal-body",
                        m("",
                            m("form[id='basic-form'][method='post'][novalidate]",
                                m('.row.m-0', [
                                    m(".col-12", [
                                        m("span.badge.badge-info", {
                                            style: {
                                                fontSize: "1rem"
                                            }
                                        }, "視覺圖"),
                                        m(PhotoUploader, {
                                            caption: '視覺圖 960*960',
                                            path: this.getPhotoPath(this.preview()),
                                            url: `${window.BASE_URL}/api/upload/photo`,
                                            preview: true,
                                            width: 960,
                                            height: 960,
                                            viewW: 320,
                                            viewH: 320,
                                            filename: this.preview,
                                            folder: "animation",
                                        }),
                                    ]),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "標題"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.title = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.title,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "Description"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.description = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.description,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "Keywords"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.keywords = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.keywords,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-12',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "Youtube"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.youtube = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.youtube,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-12',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "Website"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.website = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.website,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),
                                    m('.col-md-4',
                                        m(".form-group", [
                                            m("label.text-dark.mb-1", "排序"),
                                            [
                                                m(TextBox, {
                                                    oninput: (e) => {
                                                        this.model.sort = e.target.value
                                                    },
                                                    class: 'mt-0 form-control',
                                                    value: this.model.sort,
                                                    type: 'text',
                                                })
                                            ]
                                        ]),
                                    ),                                   
                                   ]
                                   ),
                                    
                                   
                                m('.row.m-0', [
                                    m(".form-group",
                                        [
                                            m("label.control-label", "內容"),
                                            m(Editor, {
                                                content: this.content
                                            }),
                                        ]
                                    )
                                ]),
                            )
                        )
                    ),

                    m(".modal-footer",
                        [
                            m("button.btn.btn-label.rounded", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.close()
                                }
                            },
                                [m("i.fa.fa-times-circle"), "關閉"]
                            ),
                            m("button.btn.btn-primary[type='button']", {
                                onclick: (e) => {
                                    e.preventDefault()
                                    this.save()
                                    
                                }
                            },

                                [m("i.fa.fa-check-circle"), "儲存"])
                        ],
                    )
                ]
            )
        ])]
    }
}

export default EditComponent
