const ALL = -1,
    UNRETURNED = 0,
    SUBMIT = 1,
    APPROVED = 2,
    REJECT = 3

export {
    ALL,
    UNRETURNED,
    SUBMIT,
    APPROVED,
    REJECT
}