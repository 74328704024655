import m from 'mithril'
import stream from 'mithril/stream'
import moment from 'moment'
import {
    Editor
} from 'components'
import {
    About,
} from 'app/models'

class AboutPage {
    constructor() {
        this.model = new About()
        this.content = stream("")
        About.fetch().then((response)=>{
            this.model = new About(response)
            this.content(this.model.content)
        })
    }
    view() {
        return [
            m(".row",
                m(".col-xl-12",
                    m(".breadcrumb-holder",
                        [
                            m("h1.main-title.float-left.text-menu",
                                m("i.lnr.lnr-book.font-weight-bold.mr-3"), "這是一個關於你的故事"
                            ),
                            m("ol.breadcrumb.float-right",
                                [
                                    m("li.breadcrumb-item",
                                        "首頁"
                                    ),
                                    m("li.breadcrumb-item.active",
                                        "About"
                                    )
                                ]
                            ),
                            m(".clearfix")
                        ]
                    )
                )
            ),
            m(".row",
                m(".col-md-12",
                    m(".panel-content",
                        m(".card.mb-3",
                            [
                                m(".card-header",
                                    m('.d-flex.justify-content-between.align-items-center',
                                        [
                                            m("h3", [m("i.fa.fa-table.mr-2"), "Your Story"]),
                                            m("button.btn.btn-primary.btn-sm[type='button']", {
                                                onclick: (e) => {
                                                    e.preventDefault()
                                                    this.model.content = this.content()
                                                    this.model.save().then((_response)=>{
                                                        console.log(_response)
                                                        swal({
                                                            title: "編輯成功!",
                                                            text: "已成功編輯資訊，請至列表查看!!",
                                                            icon: "success",
                                                            buttons: false,
                                                            timer: 1800,
                                                        })
                                                    })
                                                }
                                            }, [m("i.fa.fa-plus-circle.mr-1"), "存檔"])
                                        ]
                                    ),
                                ),
                                m(".card-header", [
                                    

                                    (About.loading) ?
                                        [
                                            m(".row.w-100.text-dark.border-bottom.align-items-center.py-2", {
                                                style: {
                                                    fontSize: "85%",
                                                    fontWeight: 700,
                                                    lineHeight: 1,
                                                }
                                            }, [
                                                    m('.col-12.text-center.py-2', [
                                                        m('span', {
                                                            style: {
                                                                fontSize: "1.5rem"
                                                            }
                                                        }, "資料讀取中...")
                                                    ])
                                                ])

                                        ] : [
                                            m(".row.w-100.text-light.border-bottom.pb-1", [
                                                m(Editor, {
                                                    content: this.content
                                                }),

                                            ]),
                                                             
                                        ]
                                ]
                                ),                                
                            ]
                        )
                    )
                )
            ),
        ]
    }
}

export default AboutPage